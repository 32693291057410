var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ThemeProvider',{staticClass:"page"},[_vm._l((_vm.headerElements),function(element){return _c('PageElement',{key:element.id,attrs:{"element":element,"mode":_vm.mode,"application-context-additions":{
      recordIndexPath: [],
    }}})}),_vm._v(" "),_vm._l((_vm.elements),function(element){return _c('PageElement',{key:element.id,attrs:{"element":element,"mode":_vm.mode,"application-context-additions":{
      recordIndexPath: [],
    }}})}),_vm._v(" "),_vm._l((_vm.footerElements),function(element){return _c('PageElement',{key:element.id,attrs:{"element":element,"mode":_vm.mode,"application-context-additions":{
      recordIndexPath: [],
    }}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }